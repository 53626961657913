(function ($) {

  $(document).ready(function () {


    $('body').scrollToAnchors();

    $('.glyphicons').glyphClone();

    $('.passage a, .paragraph-publication a, a[href*="gaia-group.com"], a[href*="servier.com"], a[href*="google.de"]').not('.btn').attr('target', '_blank');

    $('.paragraph a[title^=button], .passage a[title^=button], .jumbotron a[title^=button], .stripe a[title^=button]').mdLinkToButton();

    // **********************
    $('body').bobButton();

    $(window).scrollTopArrow();

    $('.footer span[data-class^=foo]').mdSpanStyles();

    $('body').fadePage();
    
    $('body').bobFormContact(document.bobData.contact);

    $('.bob-frame-show').bobframe();


  });

}(jQuery));
