(function ($) {

  /**
 * bob message plugin
 * All the functions that are needed for displaying and hiding of the bob messages
 * Handles the focus for accessibility
 * This is designed for a Form that lives directly on the page. (not in a frame)
 * @returns {$this}
 */

$.fn.bobMsg = function (options) {

  if(!_.isObject(options)) {
    console.error('please provide a setting object to bobMsg in main.js')
  }

  let throwEvent = function(element, eName, eType) {
    $(element).trigger($.Event(eName, {eType})); 
  }

  let $this = this;
  let msg = null;
  let successTimeout = null;
  let settings = $.extend({}, options );
  let msgFormClass = `trigger-${settings.classForm.replace(".", '')}`;

  $this.bobMsgHide = function (e) {

    $(settings.classMsg).hide();
    $(settings.classMsg + ' .msg-content').hide();

    if (e?.trigger !== 'submit') {
      if (settings.envBobFrame === 'false') {
        $this.handleFocusPage();
      } else {
        $this.handleFocusFrame();
      }
    }

    msg = null;
    sessionStorage.setItem('bobMsgOpen', 'false');

    $(settings.classMsg).removeClass(function (index, className) {
      return (className.match (/(^|\s)trigger-\S+/g) || []).join(' ');
    });

    $(settings.classMsg).removeClass('active success warning error');

  };


  $this.smartHideBobMsg = function (e) {

    let activeMsg = $('.active');

    if( activeMsg && !activeMsg.hasClass('success') )   {

      activeMsg.hide();
      activeMsg.removeClass(function (index, className) {
        return (className.match (/(^|\s)trigger-\S+/g) || []).join(' ');
      });

      activeMsg.removeClass('active sucess warning error');
    }

    if (settings.envBobFrame === 'false') {
      $this.handleFocusPage();
    } else {
      $this.handleFocusFrame();
    }
    
  }

  $this.handleFocusPage = function () {

    let inputs = $('.form-group input.is-invalid', settings.classForm);
    let focusTarget = inputs[0] ?? $('.submit', settings.classForm);

    focusTarget.focus();
  }

  $this.handleFocusFrame = function () {

    if (sessionStorage.getItem("frameOpen") !== 'undefined' && sessionStorage.getItem("frameOpen") !== null) {

      if (JSON.parse(sessionStorage.getItem("frameOpen")) === true) {

        $(settings.classMsg + ' .msg-close').removeAttr('tabindex');

        // find the first invalid inpit field and focus it.
        let inputs = $('.form-group input.is-invalid', '.bob-frame');
        inputs[0]?.focus();

      } else {
        // focus the initial button that opened the frame
        $(settings.classMsg + ' .msg-close').removeAttr('tabindex');

        if (sessionStorage.getItem("triggerItem") !== 'undefined') {
          let triggerItem = $('#' + JSON.parse(sessionStorage.getItem("triggerItem")));
          triggerItem.focus();
        }
      }
    }
  }

  $this.bobMsgSuccess = function () {
    msg = 'success';
    $this.bobMsgShow(msg);
    successTimeout = window.setTimeout(bobMsgHide, 3000, 200);

  };

  $this.bobMsgWarning = function () {
    msg = 'warning';
    $this.bobMsgShow(msg);
  };

  $this.bobMsgError = function () {
    msg = 'error';
    $this.bobMsgShow(msg);
  };

  $this.bobMsgErrorChecksum = function () {
    msg = 'error-checksum';
    $this.bobMsgShow(msg);
  };

  $this.bobMsgErrorRequestDeclined = function () {
    msg = 'error-request-declined';
    $this.bobMsgShow(msg);
  };

  $this.bobMsgShow = function (msg) {

    clearTimeout(successTimeout);
    
    let msgToShow = $(settings.classMsg + ' .msg-' + msg);
    
    msgToShow.show();
    $(settings.classMsg).addClass(`active ${msg} ${msgFormClass}`);
    // this sets rhe aria-label of msg-close depending on the message shown
    let ariaLabel = $('.msg-content-txt', msgToShow).html() + ' Mit Enter können sie diese Nachricht schließen.'
    $('.msg-close').attr('aria-label', ariaLabel);
    
    $(settings.classMsg).fadeIn(200);

    // when triggered, this event will focus the X symbol to read the dynamic aria-label
    $('.msg-close', settings.classMsg).focus();

    sessionStorage.setItem('bobMsgOpen', 'true');
  }

  /**
 * EVENT LISTENERS
 */

  $(document).on('click', "." + msgFormClass, function (e) {
    $this.bobMsgHide(e);
  });

  // this event is triggerd in the form.js
  $(settings.classForm).on('sendDataStart', function (e) {
    $this.bobMsgHide(e);
  });

  $(settings.classForm).on('sendDataInvalid sendDataError sendDataSuccess', function (e) {
    $this.bobMsgShow(e.eType);
  });

  // this event is triggerd in the bobframe.js
  $(document).on('bobHideFrame', function (e) {
    $this.smartHideBobMsg(e);
  });

  return $this;
};

}(jQuery));