(function ($) {

    /**
     * zen contact plugin
     * Sending form to bob-server with zen endpoint.
     * @returns {jQuery}
     */

    $.fn.bobFormContact = function (options) {

        if (!_.isObject(options)) {
            console.error("please define settings for this plugin in main.js");
            return this;
        }

        let settings = $.extend({}, options);
        $(settings.classForm).bobMsg(settings);

        /**
         * Toggle spinner
         */
        let spinner = function (toggle) {
            if (toggle) {
                $('document').spinner('show');
            } else {
                $('document').spinner('hide');
            }
        };

        var sendData = function (e) {

            e.preventDefault();

            let pre = $(e.currentTarget).attr('data-prefix');

            $('#' + pre + 'submit', '.bob-frame').prop('disabled', true);
            $('.form-group input', '.bob-frame').removeClass('is-invalid');

            _.throwEvent(settings.classForm, 'sendDataStart', settings.classForm);

            var data = {};
            var label = {};
            $(':input', e.target).each(function () {


                data[this.name] = $(this).val();
                label[this.name] = $("label[for='" + $(this).attr('id') + "']", e.target).text();
            });

            var notValid = false;
            if (data.name.trim() === "") {
                $('.form-group-name input', '.bob-frame').addClass('is-invalid');
                notValid = true;
            } else {
                $('.form-group-name input', '.bob-frame').removeClass('is-invalid');
                data.description = label.name + ": " + data.name + "\n\n" + data.description;
            }

            if (data.email.trim() === "" || _.validateEmail(data.email.trim()) === false) {
                $('.form-group-email input', '.bob-frame').addClass('is-invalid');
                notValid = true;
            } else {
                $('.form-group-email input', '.bob-frame').removeClass('is-invalid');
                data.description = label.email + ": " + data.email + "\n\n" + data.description;
            }

            if (notValid) {
                // enable button again
                $('#' + pre + 'submit', '.bob-frame').prop('disabled', false);

                _.throwEvent(settings.classForm, 'sendDataInvalid', 'warning');

                return;
            }

            spinner(true);

            $.ajax({
                url: "/zen",
                type: "POST",
                data: data,
                cache: false,
                success: function (response) {
                    _.throwEvent(settings.classForm, 'sendDataSuccess', 'success');

                    // myObject, myCategory, myAction
                    // myType is defined in util. Its always the value of et_getPageName()
                    _.eTracker2('kontakt', 'form', 'kontakt-success');
                    // enable button again
                    $('#' + pre + 'submit', '.bob-frame').prop('disabled', false);
                    spinner(false);

                },
                error: function (xhr, b, x) {
                    _.throwEvent(settings.classForm, 'sendDataError', 'error');

                    // enable button again and hide the spinner
                    $('#' + pre + 'submit', '.bob-frame').prop('disabled', false);
                    spinner(false);
                }
            });

        };



        $(document).off('.contactform');

        $(document).on('submit.contactform', '.bob-frame form' + settings.classForm, function (e) {
            sendData(e);
        });
        return this;

    };

}(jQuery));
