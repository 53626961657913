(function ($) {

    $.fn.mdSpanStyles = function (e) {

            var elements = $(this);
            
            $.each( elements, function( key, element ) {
                
                let pclass = $(element).data('class');
                $(element).parent('p').addClass(pclass);

                $(element).remove();
                
              });

    };

}(jQuery));