(function ($) {

  let throwEvent = function (element, eName, eType) {
    $(element).trigger($.Event(eName, {
      eType
    }));
  }

  let eTracker = function (myObject, myCategory, myAction, myType) {
    if (typeof (_etracker) === "object") {
      _etracker.sendEvent(new et_UserDefinedEvent(myObject, myCategory, myAction, myType));
    }
  };

  // TO DO: switch all projects to the new eTracker2 function
  let eTracker2 = function (myObject, myCategory, myAction) {
    if (typeof (_etracker) === "object") {
      let myType = et_getPageName();
      _etracker.sendEvent(new et_UserDefinedEvent(myObject, myCategory, myAction, myType));
    }
  };

  let eTrackerOrder = function (id) {

    if (_.isNil(id)) {
      console.error("please define etracker id");
      return this;
    }

    if (typeof (_etracker) === "object") {

      let name = et_getPageName() + '/conversion';
      let tonr = Date.now();

      et_eC_Wrapper({
        et_et: id,
        et_pagename: name,
        et_tval: "1", //Conversion value
        et_tonr: tonr, //Order number
        et_tsale: 1 //Conversion status sale
      });

    }
  };

  let validateEmail = function (email) {
    let re = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
    let regex = new RegExp(re);
    return regex.test(email);
  };

  let validateTokenHelsana = function (input) {
    var token = input.trim();
    var re = /^[0-9]{8}$/;
    return re.test(token)
  }

  let changeFormateDateIso = function (oldDate) {

    // check if it is not iso formatted already
    if (!/^\d{4}/.test(oldDate)) {
      return oldDate.toString().split(".").reverse().join("-");
    }

    return oldDate

  }

  let changeFormateDateUser = function (oldDate) {
    return oldDate.toString().split("-").reverse().join(".");
}

  let normalizeInput = function (input) {
    let reg = /[-\/]/g;

    if (reg.test(input)) {
        return input.replace(reg, '.');
    }
    return input
}

  let validateDate = function (cDate) {

    let isoDate = changeFormateDateIso(normalizeInput(cDate));
    return !isNaN(new Date(isoDate).getDate())

  }

  _.mixin({
    'changeFormateDateIso': changeFormateDateIso,
    'changeFormateDateUser': changeFormateDateUser,
    'eTracker': eTracker,
    'eTracker2': eTracker2,
    'eTrackerOrder': eTrackerOrder,
    'normalizeInput': normalizeInput,
    'throwEvent': throwEvent,
    'validateDate': validateDate,
    'validateEmail': validateEmail,
    'validateTokenHelsana': validateTokenHelsana,
  });

}(jQuery));