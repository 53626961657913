(function ($) {

    $.fn.bobframe = function () {

        // let element = this;
        let frameOpen = false;

        let throwEvent = function (element, eName, eType) {
            $(element).trigger($.Event(eName, {
                eType
            }));
        }

        let bobHide = function (e) {
            let $frame;

            if (e.currentTarget === window) {

                $('.bob-frame').each(function (i, m) {
                    if ($(m).css('display') == 'block') {
                        $frame = $(m);
                    }
                });
            }

            if (e.currentTarget == window.document) {
                // esc
                $('.bob-frame').each(function (i, m) {
                    if ($(m).css('display') == 'block') {
                        $frame = $(m);
                    }
                });
            } else {
                // frame or button
                let $currentTarget = $(e.currentTarget);
                if ($currentTarget.hasClass('bob-frame')) {
                    $frame = $currentTarget;
                } else if (!$frame) {
                    $frame = $($currentTarget.parents('.bob-frame')[0]);
                }
            }

            // console.log('bobframe4', $frame, e);
            if (!$frame) {
                return;
            }
            if ($frame.css('display') === 'none') {
                return;
            }

            // console.log('bobHide', $frame, e);
            let scrollFrame = $(window).scrollTop();

            $frame.addClass('bob-fading-in');
            $frame.css('top', '-' + scrollFrame + 'px');
            // console.log('top', '-'+scrollFrame+'px', $frame.css('top'));

            window.setTimeout(function() {

                $('body').removeClass('bob-frame-open');

                let scrollPosMain = sessionStorage.getItem("scrollPosMain");

                // This makes sure screenreaders have their focus on the element that triggered the bob-frame, when closing the bob-frame.
                // Otherwise the focus would jump to the top of the page. 
                // For this to work the triggering button or link needs to be given an ID. 

                if (sessionStorage.getItem("triggerItem") !== 'undefined' && sessionStorage.getItem("triggerItem") !== null) {

                    let triggerItem = $('#' + JSON.parse(sessionStorage.getItem("triggerItem")));
                    let bobMsgOpen = false;

                    if (sessionStorage.getItem('bobMsgOpen') !== null) {

                        bobMsgOpen = JSON.parse(sessionStorage.getItem('bobMsgOpen'));

                        if (bobMsgOpen === false) {
                            triggerItem.trigger('focus');
                        }
                    } else {
                        triggerItem.trigger('focus');
                    }
                }

                $(window).scrollTop(scrollPosMain);

                // listener can be found in bobmsg2
                throwEvent(document, 'bobHideFrame', 'test');

            }, 100);

            $frame.fadeOut(400, function () {
                $frame.css('top', '0');
                $(this).find('.bob-content').html('');

                let event = $.Event('afterhide.bobframe');
                $frame.trigger(event);
                $frame.removeClass('bob-fading-in');

                let removeAllMatchingClasses = function () {

                    let matches = $frame.attr('class').match(/contains-\S*/g);

                    $.each(matches, function () {
                        let className = this;
                        $frame.removeClass(className.toString());
                    });

                }

                removeAllMatchingClasses();
            });


            frameOpen = false;
            sessionStorage.setItem('frameOpen', JSON.stringify(frameOpen));

            // in cases there is a bootstrap carousel on a given site
            // it needs to be restarted on bob close
            if ($("#myCarousel").length) {

                let slider = $("#myCarousel");
                let iActive;

                // find the index of the currently active slide
                $('.carousel-item').each(function (index) {

                    if ($(this).hasClass('active')) {
                        iActive = index;
                    }

                });

                slider.carousel("pause").removeData();

                if (iActive !== 0) {
                    slider.carousel(0);
                } else {
                    slider.carousel(1);
                }

                slider.carousel('cycle');

                // the carousel hides the formerly active element
                // just switching or removing the active class would cause problems
                setTimeout(function () {
                    $('.carousel-item').eq(iActive).css('display', '');
                }, 200);
            }

        };

        let bobShow = function (e, item) {

            e.preventDefault();

            // this state is used to allow the user to press the back button to close the bob-brame
            window.history.pushState('forward', null, './#bob-frame');

            $('.bob-msg').hide();

            let src = $(item).attr('href');

            if (src) {

                let target = $(item).attr('data-target');
                $('.' + target + ' .bob-content').html('');

                if (src.substr(0, 1) === '#') {

                    let copy = $(src).clone();
                    let copyid = copy.attr('id');
                    if (copyid) {
                        copy.attr('id', copyid + '-mod');
                        $('.bob-frame').addClass('contains-' + copyid);
                    }

                    // this is to ensure the IDs in the copied form are unique
                    let suffix = '-in-frame';
                    let ids = $('input[id], textarea[id], button[id], a[id], select[id]', copy).map(function () {

                        if (this.id) {
                            this.id = this.id + suffix
                        }

                        return this.id;
                    }).get();

                    let fors = $('[for]', copy).map(function () {

                        let old = this.getAttribute('for');
                        this.setAttribute('for', old + suffix);

                        return this

                    }).get();

                    // IE bug: when cloning, placeholder text is inserted as value
                    // Fix: if placeholder equals value, remove value
                    $('textarea', copy).each(function (i, ta) {
                        if ($(ta).attr('placeholder') == $(ta).val()) {
                            $(ta).val('');
                        }
                    }); // end IE bugfix
                    copy.hide();
                    $('.' + target + ' .bob-content').html(copy);
                    copy.fadeIn();
                }

                frameOpen = true;

                let sessionData = {
                    "scrollPosMain": $(window).scrollTop(),
                    "triggerItem": $(item).attr('id'),
                    "frameOpen": frameOpen
                }

                sessionStorage.setItem('scrollPosMain', JSON.stringify(sessionData.scrollPosMain));
                sessionStorage.setItem('triggerItem', JSON.stringify(sessionData.triggerItem));
                sessionStorage.setItem('frameOpen', JSON.stringify(sessionData.frameOpen));

                $('.' + target).hide(0, function () {

                    $('.' + target).addClass("bob-fading-in");

                    $('.' + target).fadeIn(200, function () {
                        $(window).scrollTop(0);
                        $('.' + target).removeClass("bob-fading-in");
                        $('body').addClass('bob-frame-open');
                    });

                    // When opening the form, the first input is focused. 
                    // This will make the screenreader explain instatly whats going on in the form
                    let firstElementInForm = $('.' + target).find('input').first();
                    setTimeout(function () {
                        $(firstElementInForm).focus();
                    }, 200);

                });

            }

        };

        // use to initialise
        if (arguments.length === 0) {

            $(document).off('.bobframe');

            $(document).on('click.bobframe', '.bob-frame-show', function (e) {

                if ($(this).attr('href') && !($(this).attr('href').indexOf('mailto') > -1)) {
                    bobShow(e, this);
                }
            });

            $(document).on('click.bobframe', '.bob-close', function (e) {
                window.history.back();
            });

            $(document).on('keydown.bobframe', function (e) {
                e.stopPropagation();

                e = e || window.event;
                if (e.keyCode === 27) {
                    if (sessionStorage.getItem("frameOpen") === 'true' && sessionStorage.getItem("bobMsgOpen") === 'false') {
                        bobHide(e);
                    }
                }
            });

            $('.bob-frame').on('sendDataSuccess.bobframe', function (e) {
                bobHide(e);
            });

            // this hides the bob-frame if the browsers-back button is pressed!
            $(window).on('popstate.bobframe', function (e) {
                bobHide(e);
            });

        }

        return this;

    };

}(jQuery));